import React, { useContext, useRef, useEffect, useState } from 'react'
import axios from 'axios'
import UploadPDF from '../Components/UploadPDF'
import Header from '../../Main/Components/Header'
import Footer from '../../SharedComponents/js/Footer'
import { NavLink, useNavigate } from 'react-router-dom';
import '../css/Generate.css'
import Loading from '../../SharedComponents/js/Loading';
import { useErrorBoundary } from "react-error-boundary";
import { useStateValue } from "../../index";
import AutoGenerate from '../Components/AutoGenerate';
import FingerLeftMenu from '../../Main/Components/Main/FingerLeftMenu';
import DocumentMetadata from '../Components/DocumentMetadata';
import FreeGenerate from '../Components/FreeGenerate'
import DragDropUpload from '../Components/DragDropUpload'
import VideoBackgrounds from '../Components/VideoBackgrounds'
import { getVideoBackgrounds } from '../../utilities/helpers'
import MiniFooter from '../../SharedComponents/js/Mini-Footer'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const env = require('../../config.env')

const GenerateVideoClip = () => {
    const navigate = useNavigate()
    const [stop_process, set_stop_process] = useState(false)
    const { showBoundary } = useErrorBoundary();
    const logoutTrigger = useRef(false)

    const [isgenerate, setisgenerate] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [generateSocket, setgenerateSocket] = useState(null)
    const [voiceType, setvoice_type] = useState()

    const [upload_content, setupload_content] = useState(false)
    const [canvas_content, setcanvas_content] = useState(false)
    const [custom_content, setcustom_content] = useState(false)
    const [free_content, setfree_content] = useState(false)
    const [auto_content, setauto_content] = useState(false)
    const [hide_metadata, sethide_metadata] = useState(true)
    const [userdata, dispatch] = useStateValue();

    const [figures, getfigures] = useState()
    const [summarized_sections, getsections] = useState([])
    const [section_titles, getsection_titles] = useState([])

    const [doc_interest, set_interest] = useState()
    const [is_author, set_is_author] = useState(false)
    const [is_share, set_is_share] = useState(false)
    const [doc_id, setdoc_id] = useState('')
    const doc_id_ref = useRef()
    const [docpublisher, getdocpublisher] = useState()
    const [docsubjects, getdocsubjects] = useState([])
    const [doc_subject_str, getSubjectstr] = useState()
    const [doctype, getdoctype] = useState()
    const [docjournaltitle, getdocjournaltitle] = useState()
    const [docpublicationDate, getdocpublicationDate] = useState()
    const [doc_title, getdoc_title] = useState([])
    const [doc_URL, getdoc_URL] = useState()
    const [authors, getauthors] = useState([])
    const [hasError, setHasError] = useState(false)
    const [errMsg, setErrMsg] = useState()


    const [videoData, setVideoData] = useState()
    const [videoId, setVideoId] = useState()
    const [is_generate_done, set_is_done_flages] = useState()
    const [hasGenPermission, get_hasGenPermission] = useState()
    const search_BK_Ref = useRef([])

    const [rt_frames, set_rt_frames] = useState([])

    useEffect(() => {
        setupload_content(true)
        setcustom_content(false)
        setfree_content(false)
        setauto_content(false)
        sethide_metadata(true)
        axios.get(env.SERVER_REQUEST_PATH + 'hasGenPermission')
            .then((response) => {
                get_hasGenPermission(response.data.has_permission)
                if (!response.data.has_permission)
                    toast.error(response.data.message, {
                        position: "top-center"
                    });

            }).catch(err => {
                handleErrors(err)
            })

    }, [])
    const deleteDocument = async () => {
        await axios.post(`${env.SERVER_REQUEST_PATH}deleteDocument`, { doc_id: doc_id_ref.current })
    }
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // if (doc_id_ref.current) {
            if (!logoutTrigger.current) {
                deleteDocument()
                event.preventDefault();
                event.returnValue = '';
            }
            // }
            // const data = JSON.stringify({ data: doc_id });
            // const headers = { type: 'application/json' };
            // navigator.sendBeacon(`${env.SERVER_REQUEST_PATH}deleteDocument`, new Blob([data], headers))
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        // Create a new WebSocket connection
        if (isgenerate) {
            const socket = new WebSocket(env.SERVER_SOCKET_PATH);

            // Event listener for when the connection is opened
            socket.onopen = () => {
                // console.log('WebSocket connection established');

                // Send some data to the server
                const data = {
                    type: 'identification',
                    clientId: doc_id
                };
                socket.send(JSON.stringify(data));
            };

            // Event listener for when a message is received from the server
            socket.onmessage = (event) => {
                // console.log('Received message from server:', event.data);
                let data = JSON.parse(event.data)
                setVideoData({
                    video_path: data.videoPath,
                    thumb_img: data.thumb_img,
                    subtitle: data.subtitle
                })
                setVideoId(data.video_id)
                restErrors()
                set_is_done_flages({ retry: false, running: false })
                setisgenerate(false)

                // Handle the received message
            };

            // Event listener for when the connection is closed
            socket.onclose = () => {
                // console.log('WebSocket connection closed');
                // if (is_generate_done != undefined)
                //     isdoneGenerate(is_generate_done)
            };

            // Event listener for errors
            socket.onerror = (error) => {
                // console.error('WebSocket error:', error);
                handleErrors(error)
                set_stop_process(true)
                set_is_done_flages({ retry: true, running: false })
                // console.error(`Error: ${error}`)
            };
            setgenerateSocket(socket)
        }
        else {
            if (generateSocket)
                generateSocket.close();
        }

    }, [isgenerate]);

    const restErrors = () => {
        setHasError(false)
        setErrMsg('')
    }
    const showErrors = (err) => {
        setHasError(true)
        setErrMsg(err)
    }
    const handleErrors = (err, continue_process) => {
        setIsLoading(false)
        if (continue_process)
            showErrors(err.message)
        else
            showErrors("Something went wrong with this document, We're working on it and we will get back to you")
        // if (err.status == 500)
        //     showBoundary(err)
        // else {
        //     showErrors(err.message)
        // }
    }

    const onCancel = (flag) => {
        if (flag) {
            setIsLoading(true)

            axios.post(env.SERVER_REQUEST_PATH + 'cancel', { doc_id: doc_id })
                .then((response) => {
                    restErrors()
                    setIsLoading(false)

                }).catch(err => {
                    handleErrors(err)
                    set_stop_process(true)
                    // console.error(`Error: ${err}`)
                })
        }
        navigate('/home')
    }
    const publishOrSaveVid = (VideoId, title, descr, taggedUsers) => {
        setIsLoading(true)
        let req_data = {
            title: title, descr: descr, is_author: is_author,
            video_id: VideoId, taggedUsers: taggedUsers
        }
        axios.post(env.SERVER_REQUEST_PATH + 'publishOrSave', req_data)
            .then((response) => {

                let status = response.status
                if (status == 200) {
                    restErrors()
                    navigate(`/video/${VideoId}`, { state: { is_private: response.data.is_private } })
                }
                setIsLoading(false)

            }).catch(err => {
                handleErrors(err)
                set_stop_process(true)
                // console.error(`Error: ${err}`)
            })
    }
    const submit_Upload = (docId) => {
        setdoc_id(docId)
        doc_id_ref.current = docId
        // set_interest(selectedinterest)
        // set_is_author(is_by_author)
        // set_is_share(is_share)
        // setvoice_type(voice_type)
        summarizeDocument(false, docId)
    }
    const summarizeDocument = (is_regenerate, docID) => {
        setIsLoading(true)
        let req_sec_data = {
            doc_id: docID,//, DOI: DOI
            is_regenerate: is_regenerate
        }
        axios.post(env.SERVER_REQUEST_PATH + 'save_and_summarize', req_sec_data)

            // axios.post(env.SERVER_REQUEST_PATH + 'summarize_doc', req_sec_data)
            .then((response) => {
                let data = []
                let title_frame = {
                    "doi": response.data.summarize_sec.sections[0].doi,
                    "longSummary": response.data.summarize_sec.sections[0].paperTitle,
                    "paperAuthors": response.data.summarize_sec.sections[0].paperAuthors,
                    "paperTitle": response.data.summarize_sec.sections[0].paperTitle,
                    "shortSummary": response.data.summarize_sec.sections[0].paperTitle,
                    "researchInterest": response.data.summarize_sec.sections[0].researchInterest
                }
                data.push(title_frame)
                let copy_arr = response.data.summarize_sec.sections.slice(0, 9)
                data.push(...copy_arr)
                set_interest(data[0].researchInterest)
                getsections(data)
                getdoc_title(data[0].paperTitle)
                if (!is_regenerate) {
                    getdoctype(response.data.metadata?.type)

                    getdocsubjects(response.data.metadata?.subject ? response.data.metadata?.subject : [])
                    let subject_str = ""
                    if (response.data.metadata?.subject) {
                        response.data.metadata.subject.map((subject, index) => {
                            subject_str += subject
                            if (index < response.data.metadata.subject.length - 1)
                                subject_str += ','
                        })
                    }
                    getSubjectstr(subject_str)

                    getdocpublisher(response.data.metadata?.publisher)
                    getdocjournaltitle(response.data.metadata?.journalTitle?.[0])
                    getdocpublicationDate(response.data.metadata?.publicationDate)
                    getauthors(response.data.metadata?.authors)
                    getdoc_URL(response.data.metadata?.URL)
                }
                setupload_content(false)
                setcanvas_content(true)
                setIsLoading(false)

                restErrors()

                sethide_metadata(false)
            }).catch(err => {
                handleErrors(err)
                set_stop_process(true)
                // console.error(`Error: ${err}`)
            })

    }
    const generateVideo = (frames, track_name, selected_transitions) => {
        try {
            setIsLoading(true)
            let transitions = selected_transitions[0] ? selected_transitions[0] + "," : "zoomin,"
            let frames_data = []
            let download_v_links = []
            frames.map((frame, index) => {
                let frame_text1lines = []
                let frame_text1_x = []
                let frame_text1_y = []
                let frame_text1_width = []
                let frame_text2lines = []
                let frame_text2_x = []
                let frame_text2_y = []
                let frame_text2_width = []
                frame.scaled_lines1.map((scaledline) => {
                    frame_text1lines.push(scaledline.line)
                    frame_text1_x.push(Math.round(scaledline.scaled_x).toString())
                    frame_text1_y.push(Math.round(scaledline.scaled_y).toString())
                    frame_text1_width.push(Math.round(scaledline.scaled_width).toString())
                })
                if (frame.scaled_lines2) {
                    frame.scaled_lines2.map((scaledline) => {
                        frame_text2lines.push(scaledline.line)
                        frame_text2_x.push(Math.round(scaledline.scaled_x).toString())
                        frame_text2_y.push(Math.round(scaledline.scaled_y).toString())
                        frame_text2_width.push(Math.round(scaledline.scaled_width).toString())
                    })
                }
                let frame_details = {
                    videoIsTitle: frame.videoIsTitle,
                    inputResolutionWidth: frame.inputResolutionWidth.toString(),
                    inputResolutionHeight: frame.inputResolutionHeight.toString(),
                    text1: frame_text1lines,
                    text1x1: frame_text1_x,
                    text1y1: frame_text1_y,
                    text1width: frame_text1_width,
                    text1FontSize: Math.round(frame.text1FontSize).toString(),
                    text1FontFamily: frame.text1FontFamily,
                    text1Color: "white",//frame.text1Color,
                    text1BackgroundColor: "black@0.5",//frame.text1BackgroundColor,
                    text1boxborderw: Math.round(frame.text1boxborderw).toString(),
                    canvas2videoRatio: frame.averageScale.toString()
                }
                if (frame.scaled_lines2?.length > 0) {
                    frame_details = {
                        ...frame_details,
                        text2: frame_text2lines,
                        text2x1: frame_text2_x,
                        text2y1: frame_text2_y,
                        text2width: frame_text2_width,
                        text2FontSize: Math.round(frame.text2FontSize).toString(),
                        text2FontFamily: frame.text2FontFamily,
                        text2Color: "white",//frame.text2Color,
                        text2BackgroundColor: "transparent",//frame.text2BackgroundColor,
                        text2boxborderw: Math.round(frame.text2boxborderw).toString()
                    }
                }
                transitions += selected_transitions[index + 1] ? selected_transitions[index + 1] : "zoomin"
                if (index < frames.length - 1)
                    transitions += ","
                frames_data.push(frame_details)
                download_v_links.push({
                    url: frame.v_url,
                    v_id: frame.id
                })
            })
            let req_data = {
                title: doc_title,
                interest: doc_interest,
                doc_id: doc_id,
                track_name: track_name,
                desiredResolution: "1920x1080",
                transitionTypes: transitions,
                frames_data: frames_data,
                download_v_links: download_v_links
            }
            // console.log(JSON.stringify(req_data))
            axios.post(env.SERVER_REQUEST_PATH + 'asyncgeneratevideoclip',
                req_data
            )
                .then((response) => {
                    setisgenerate(true)
                    setIsLoading(false)
                    navigate(`/profile/${userdata.user.username}`, { state: { type: '5', is_generate: true } })
                }).catch(err => {
                    handleErrors(err)
                    set_stop_process(true)
                    setIsLoading(false)
                    // console.error(`Error: ${err}`)
                })
        }
        catch (err) {
            setIsLoading(false)
        }
    }
    if (hasGenPermission)
        return (
            <div className='d-flex flex-column'>
                {isLoading && <Loading />}
                <Header userdata={userdata} handleBeforeLogout={deleteDocument}
                    logoutTriggered={() => { logoutTrigger.current = true }} />
                <div className='gen-content'>

                    <div className={`generate-container ${isLoading ? 'loading' : ''}`}>

                        {upload_content &&
                            <DragDropUpload key={"upload"}
                                mode={"vid"}
                                hasGenPermission={hasGenPermission}
                                isLoading={isLoading} beginProcess={() => { setIsLoading(true) }}
                                endProcess={() => setIsLoading(false)}
                                handleErrors={handleErrors}
                                restErrors={restErrors} permissions={userdata.user.permissions}
                                stop_process={stop_process} set_stop_process={() => { set_stop_process(true) }}
                                submit_Upload={submit_Upload} Cancel={onCancel} />}
                        {canvas_content && <VideoBackgrounds
                            doc_id={doc_id}
                            beginProcess={() => { setIsLoading(true) }}
                            endProcess={() => setIsLoading(false)}
                            subject={doc_subject_str}
                            interest={doc_interest}
                            sections={summarized_sections}
                            submitFrames={generateVideo}
                            regenerate={() => summarizeDocument(true, doc_id)}
                        />}
                    </div>
                    <div className='d-flex justify-content-end'><p className='error-msg pe-5' hidden={hasError ? '' : 'hidden'}>{errMsg}</p></div>
                </div>
                {/* <FingerLeftMenu /> */}
                <ToastContainer />
                <MiniFooter />
            </div >
        )
}

export default GenerateVideoClip
