import React, { useCallback, useEffect, useRef, useState } from 'react';
// import useFileUpload from 'react-use-file-upload';
import { FileUploader } from "react-drag-drop-files";
import '../css/UploadFile.css'
import { AiOutlineFilePdf } from "react-icons/ai";
const env = require('../../config.env')
const fileTypes = ["PDF"];

const UploadFile = (props) => {
    // const {
    //     files,
    //     fileNames,
    //     fileTypes,
    //     totalSize,
    //     totalSizeInBytes,
    //     handleDragDropEvent,
    //     clearAllFiles,
    //     createFormData,
    //     setFiles,
    //     removeFile,
    // } = useFileUpload();
    const inputRef = useRef();
    const [file, setFile] = useState(null);
    const [isDragging, setIsDragging] = useState(false);

    // useEffect(() => {
    //     if (props.formatText) {
    //         document.getElementsByClassName("file-types")[0].innerHTML = `<span>${props.formatText}<p>Maximum size: ${env.MAX_FILE_SIZE}</p></span>`;
    //     }
    // }, [props.formatText]);

    // useEffect(() => {
    //     if (props.dragdrop) {
    //         const parentElement = document.querySelector('.ejhWCc');

    //         if (parentElement) {
    //             const svgElement = parentElement.querySelector('svg');

    //             if (svgElement) {
    //                 // Create a new div element for the React Icon
    //                 const newIconElement = document.createElement('img');
    //                 newIconElement.src = "assets/uploadfile.png"
    //                 newIconElement.className = "upload_image"
    //                 // newIconElement.className = "upload_image_container";
    //                 // newIconElement.innerHTML = `<img class="upload_image" src='assets/uploadfile.png'/>`;

    //                 // Replace the SVG element with the new icon element
    //                 svgElement.replaceWith(newIconElement);
    //             }
    //         }
    //     }
    // }, [props.dragdrop]);

    useEffect(() => {
        if (file && props.labelAfterUpload) {
            document.getElementsByClassName("upload-placeholder")[0].innerHTML = `<span> ${props.labelAfterUpload}</span>`;
        }
    }, [file, props.labelAfterUpload]);

    const deleteFile = () => {
        setFile(null);
        document.getElementsByClassName("upload-placeholder")[0].innerHTML = `<span>Click here to upload your file or drag and drop.</span>`;
        props.uploadFile(null);
    };

    const handleChange = (e) => {
        if (!props.disabled) {
            setFile(e.target.files[0]);
            if (e.target.files[0])
                props.uploadFile(e.target.files[0]);
        }
    };
    const handleDragOver = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!props.disabled)
            setIsDragging(true);
    }, []);

    const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!props.disabled)
            setIsDragging(false);
    }, []);

    const handleDrop = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!props.disabled) {
            setIsDragging(false);
            const droppedFiles = e.dataTransfer.files;
            if (droppedFiles && droppedFiles.length > 0) {
                setFile(droppedFiles[0]);
                if (droppedFiles[0])
                    props.uploadFile(droppedFiles[0]);
            }
        }
    }, []);

    const handleClick = () => {
        inputRef.current.click();
    };

    return (

        <div className='my-3 d-flex flex-column justify-content-center align-items-center'>
            {/* <div className='d-flex justify-content-between'>
                <h4 className='text-align-left font-weight-bold'>Upload and attach your files</h4>
            </div>
            <p className='text-align-left fineText'>Maximum size {env.MAX_FILE_SIZE}</p> */}
            <div className='row m-0'>
                {/* <FileUploader
                    className="w-100"
                    multiple={false}
                    handleChange={handleChange}
                    name="file"
                    types={props.fileType}
                    label={props.label}
                /> */}

                <div class={`mx-auto col-12 col-md-7 file-container d-flex justify-content-center flex-column ${props.disabled ? 'opacity-50' : ''}`} htmlFor="file"
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    onClick={handleClick}>
                    <input disabled={props.disabled ? 'disabled' : ''} ref={inputRef} onChange={handleChange} multiple={false}
                        hidden="hidden" accept={props.fileType} type="file" name="file" />
                    <img src={env.SERVER_MEDIA_PATH + 'uploadfile2.png'} class="upload_image m-auto" />
                    <div class="file-details-container text-center">
                        <span class="upload-placeholder">
                            Click here to upload your file or drag and drop.</span>
                        <span title="types: pdf" class="file-types">
                            <span className='d-flex flex-column align-items-center justify-content-center;'>
                                {`Suported formats: ${props.fileType}`}
                                <p>{`Maximum size: ${env.MAX_FILE_SIZE}`}</p>
                            </span></span>
                    </div>
                </div>

            </div>
            {
                file && <div className='mt-3 h-auto upload-title'>
                    <div className=' d-flex justify-content-between  py-2 px-2'>
                        <p className='text-wrap text-break upload-title-p m-0'>{file.name}</p>
                        <img src={env.SERVER_MEDIA_PATH + 'x-symbol.svg'} width="10"
                            className='deletex' onClick={() => deleteFile()} />
                    </div>
                </div>
            }
        </div >
        // </div >
    )
}

export default UploadFile
